<template>
    <div class="container">
        <h1> 负载均衡</h1>
        <iframe id="myiframe" :src="iframeUrl" scrolling="no" style="width: 100%;height: 90vh;" frameborder="0"></iframe>
    </div>
</template>

<script>
 import requestApi from "@/api/system.js";

export default {
    data() {
        return {
            iframeUrl: '',
        };
    },
    created() {
    this.getAccounts();
   },
    methods: {
        async getAccounts() {
        let params = {type:1};
        await this.$get(requestApi.getBaseInfo, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            let serverAcounts = res.data;
            this.iframeUrl=serverAcounts.haproxyPath;
            console.log(serverAcounts)
          }
        });
      }

    }
};
</script>

<style></style>


